import * as React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';
import PropTypes from 'prop-types';
import Button2 from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: 'default',
  color: 'secondary.main',
  fontWeight: 'medium',
};

const image = {
  height: 95,
  my: 4,
};


const imageIcon = {
  height: 30,
  my: 1,
};

function ProductHowItWorks() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open2, setOpen2] = React.useState(false);


  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };



  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}
    >


<div>
      


      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        &#127970; Alojamientos : 
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <Box sx={{ width: '100%', minWidth: 660, bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
              <Box
                  component="img"
                  src="/static/bodaimage/airbnb.png"
                  alt="suitcase"
                  sx={imageIcon}
                />
              </ListItemIcon>
              <ListItemText  >
              <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open("https://www.airbnb.mx/s/San-Gaspar--Pedregal-de-las-Fuentes--Jiutepec--Mor./homes?adults=4&place_id=EjxTYW4gR2FzcGFyLCBQZWRyZWdhbCBkZSBsYXMgRnVlbnRlcywgSml1dGVwZWMsIE1vci4sIE3DqXhpY28iLiosChQKEgmRlvaYw3XOhRFWuOFIkRwAehIUChIJNed958N1zoURt_h-RLGI4m4&checkin=2023-12-08&checkout=2023-12-10&tab_id=home_tab&refinement_paths%5B%5D=%2Fhomes&query=San%20Gaspar%2C%20Pedregal%20de%20las%20Fuentes%2C%20Jiutepec%2C%20Mor.&flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2023-08-01&monthly_length=3&price_filter_input_type=0&price_filter_num_nights=2&channel=EXPLORE&ne_lat=18.890294719138577&ne_lng=-99.15207146461807&sw_lat=18.86522298935252&sw_lng=-99.17547095345947&zoom=15.350101439040413&zoom_level=15.350101439040413&search_by_map=true&search_type=user_map_move", '_blank');
                    }}
                  >
                   <p style={{ color: 'blue' }}> Alojamientos en AirBnB cerca del jardin.</p>
                  </Link>

                </ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
              <Box
                  component="img"
                  src="/static/bodaimage/onem.png"
                  alt="suitcase"
                  sx={imageIcon}
                />
              </ListItemIcon>
              <ListItemText  >
              <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open("https://www.onehoteles.com/hoteles/one-cuernavaca/habitaciones", '_blank');
                    }}
                  >
                   <p style={{ color: 'blue' }}> Hotel One Cuernavaca</p>
                  </Link>

                </ListItemText>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
              <Box
                  component="img"
                  src="/static/bodaimage/whatsapp.png"
                  alt="suitcase"
                  sx={imageIcon}
                />
              </ListItemIcon>
              <ListItemText  >
              <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open("https://wa.me/5571419446", '_blank');
                    }}
                  >
                   <p style={{ color: 'blue' }}> Si buscas más opciones, contactanos.  </p>
                  </Link>

                </ListItemText>
            </ListItemButton>
          </ListItem>

          
        </List>
      </nav>
      <Divider />
      
    </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>



    <div>
      


      <BootstrapDialog
        onClose={handleClose2}
        aria-labelledby="customized-dialog-title"
        open={open2}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose2}>
        &#129333;  &#128131;Asistencia : 
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <Box sx={{ width: '100%', minWidth: 660, bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
                    
      <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">Nombre/Familia:</FormLabel>
      <TextField id="outlined-basic" label="" variant="outlined" />
      <Divider />
      <br></br>
      <br></br>
      <FormLabel id="demo-radio-buttons-group-label">¿Podrás Asistir a nuestro evento? </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        <FormControlLabel value="yes" control={<Radio />} label="Si puedo asistir." />
        <FormControlLabel value="no" control={<Radio />} label="Lo lamento No puedo asistir." />
      </RadioGroup>

      <br></br>
      <br></br>

      <FormLabel id="demo-radio-buttons-group-label">Deja un mensaje a los novios :</FormLabel>
      <TextField id="outlined-basic" label="" variant="outlined" />
      
      <br></br>
      <br></br>


      <Button variant="contained">Enviar</Button>


      


    </FormControl>
      </nav>
      <Divider />
      
    </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose2}>
            Cerrar
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>


      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />
        <p style={{fontFamily: 'MiFuentePersonalizada', fontSize:'70px',border:'0px',padding:'0px',margin:'0px',textAlign:'center'}}>
        Consideraciones para el gran día.
      </p>
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }}>
          
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>Vestimenta</Box>
                <Box
                  component="img"
                  src="/static/bodaimage/ropa.png"
                  alt="suitcase"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                Formal, Será un clima calido y por la noche puede hacer un poco de frio. (La novia es la unica de blanco)
                
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>Hospedaje</Box>
                <Box
                  component="img"
                  src="/static/bodaimage/5letras.png"
                  alt="graph"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  Para tu mejor comodidad te recomendamos algunos lugares para hospedarte.
                </Typography>
                <Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    component="a"
                    onClick={handleClickOpen}
                    sx={{ mt: 8 }}
                  >
                    Alojamientos
                  </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>Confirma tu asistencia</Box>
                <Box
                  component="img"
                  src="/static/bodaimage/check.png"
                  alt="clock"
                  sx={image}
                />
                <Typography variant="h5" align="center">
                  {'Esto nos ayudará a organizar la mejor fiesta.'}
                </Typography>
                ´{/*<Button
                    color="secondary"
                    size="large"
                    variant="contained"
                    component="a"
                    onClick={handleClickOpen2}
                    sx={{ mt: 8 }}
                  >
                    Confirmar en linea
                  </Button>*/}
              </Box>
            </Grid>
          </Grid>
        </div>
        
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
