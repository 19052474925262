import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';

function ProductSmokingHero() {
  return (
    <Container
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 9 }}
    >
      
        <p style={{fontFamily: 'MiFuentePersonalizada', fontSize:'40px',border:'0px',padding:'0px',margin:'0px',textAlign:'center'}}>
          ! Te esperamos !
        </p>
      {/*<Typography variant="subtitle1" sx={{ my: 3 }}>
        We are here to help. Get in touch!
      </Typography>
      <Box
        component="img"
        src="/static/themes/onepirate/productBuoy.svg"
        alt="buoy"
        sx={{ width: 60 }}
      />*/}
    </Container>
  );
}

export default ProductSmokingHero;
