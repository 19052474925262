import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import CounterDown from './CounterDown';



const backgroundImage =
  'https://lh3.googleusercontent.com/pw/AIL4fc9S5SJY4DEhEuVLkDQcoVucPw_g02eLkM8mdXzjwHtlD3m76WNnvkqw5alXOAEwqkMIYs0tF5J-kWLIPrB4Ju9C_thxY21UR3CINvwigjP-1rU81Y9YTDvgJkHGBZxGAlRp3FJFz79UiPQ7Nqtk1fP1=w1216-h912-s-no?authuser=0';

export default function ProductHero() {
  const styles =  {
    position: "absolute",
    padding: "10px",
    bottom: "0",
    width: "100%",
    textAlign:"center",
  };
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      <p style={{fontFamily: 'MiFuentePersonalizada', fontSize:'70px',border:'0px',padding:'0px',margin:'0px'}}  >
        Luisa  y Diego
      </p>
      <Typography color="inherit" align="center" variant="h6" marked="center">
       ¡ Te invitamos a ser parte de nuestra boda !
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h"
        sx={{ mb: 1, mt: { xs: 2, sm: 15 } }}
      ><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      </Typography>

      <br/><br/><br/><br/>
      <div style={styles} >
      
        <CounterDown />
      

      <Typography color="inherit" align="center" variant="h8" >
      Dias : Horas : Minutos : Segundos
      </Typography>
      

      <p style={{fontFamily: 'MiFuentePersonalizada', fontSize:'50px',border:'0px',padding:'0px',margin:'0px',textAlign:'center'}}>
        
      9 de diciembre 2023
      </p>
      {/*
      <p  >
     
               
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open("https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MGduNGFyYjhmaHJzNGw2NW11ZGF0YjA5YWwgY29yZGVyby5jYXN0cm8uZGllZ29AbQ&tmsrc=cordero.castro.diego%40gmail.com", '_blank');
                    }}
                  >
                   <p style={{ color: 'white' }}> Agregalo a tu calendaro</p>
                  </Link>
                
      </p>*/
      }
      
      </div>
    </ProductHeroLayout>
  );
}
