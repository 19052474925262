import React from 'react';
import AudioPlayer from 'react-audio-player';

const MyMusicPlayer = () => {
    const styles =  {
        padding: "10px",
        position: "fixed",
        left: "0",
        bottom: "0",
        width: "100%"
      };

       // Función para forzar el autoplay después de un retraso (en milisegundos)
    function forceAutoplay() {
        var audio = document.getElementById('audioPlayer');
        audio.play();
    }

    // Simular un evento de clic para iniciar el autoplay después de cargar la página
    document.addEventListener('DOMContentLoaded', function() {
        setTimeout(forceAutoplay, 1000);
    });

  return (
    <div  className='audioPlayer' style={styles}>
     
      
      <audio controls   src="/static/bodaimage/halo.mp3"  type="audio/mpeg" >

</audio>


    </div>
  );
};

export default MyMusicPlayer;
