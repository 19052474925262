import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import ImageGallery from 'react-image-gallery';

const ImageBackdrop = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: '#000',
  opacity: 0.5,
  transition: theme.transitions.create('opacity'),
}));

const ImageIconButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  padding: 0,
  borderRadius: 0,
  height: '40vh',
  [theme.breakpoints.down('md')]: {
    width: '100% !important',
    height: 500,
  },
  '&:hover': {
    zIndex: 1,
  },
  '&:hover .imageBackdrop': {
    opacity: 0.15,
  },
  '&:hover .imageMarked': {
    opacity: 0,
  },
  '&:hover .imageTitle': {
   // border: '4px solid currentColor',
  },
  '& .imageTitle': {
    position: 'relative',
    //padding: `${theme.spacing(2)} ${theme.spacing(4)} 14px`,
  },
  '& .imageMarked': {
    height: 30,
    width: 0,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));

const images = [
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc9giHv3UtIXvgcgRXEhSzUTTXAiypmY24bNZlhmlHL_S1m0k7UDbSMi0SK2EolsAhyinpHJD6lcpzYe56T3GzooKYNLAPvK5ZUPoCTEZVj8oOPASPS4ZmaAI0DG0ZVlHEQJ8g38j2eezS3-ZCZdnu2j=w894-h963-s-no?authuser=0',
    title: '2023',
    width: '40%',
  },
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc96EuJoWsIWisVN80c_eOheFmaPUTXBFggfrMJHIw-KAFV0l2xLC5R2GT1iRoywhDV8DRLrsnA2OGdnWo-Pxkso_M7ARSNKFmF9vqQJ2c2T7JP_B8ijksE2VWbw0w60npBzs_vnAMKXKlfrGODHDm_u=w855-h963-s-no?authuser=0',
    title: '2022',
    width: '20%',
  },
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc9HDauAakcfpjSkkJBOO6vhkyYf_ptZ828RdDUmFp__fYJeQpRN91Zgm67I-uib-Pm_NSXs_SxvepMocTtiSsIEZu8pHJYyVnP-qYgN9cjoqOCGvdDItiHT20mTdEX1Jvt4A8yDqFMR62t8yOWAdsib=w1055-h963-s-no?authuser=0',
    title: '2021',
    width: '40%',
  },
  {
    url: 'https://images.unsplash.com/photo-1453747063559-36695c8771bd?auto=format&fit=crop&w=400',
    title: '2020',
    width: '38%',
  },
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc-1hNzqlaGMODnOo2_UviEsgltvYZYXVo5clzlT9izMl1GXjCZYuzr7hPHrWetnMGdKQacXY598pRzjtfKspX-D4FaxQtfOpfncYNMbciYInK4Bh-HLdwr9Dt7P6eaZ5a9S6JMWC9Ky36CBzvChy2bg=w722-h963-s-no?authuser=0',
    title: '2019',
    width: '38%',
  },
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc8P8YDRwVmoq6vCp-QR4Sb7PYkFfZFAAagsvr5DMUt5g-i915gSG-8VNdm1p58VGnagZQlI8bsTem5f4-IYeMgmsNpwN_SlBbscsyY4Y9IsNUn7KtT-MfDwxq0GPPVnFKO3i4i7EuMVL3vbGGvlsKAn8fy_NhgDu1S6CHirVXygb7crchylR8Eow-F__fR3QHFjhjJ9GiFnqpFWX2TWGvp4KjirAsAB-vlbxaIRfRly_RumhqxuqXhYhhHbS2Cam1e4cHPFGkFrd8llD6fZ0BpOyUUCD5gJaPWWMSnK_6fM2Py4cSn5H493AYdsKeH9Ojm6QKVf-0_uAH3c55SppRoDJAL1M8CiquFtevh-xyxJm7B0bc0UelGXHzmklr1BZ_3GqFsAGWXykGkIziBNJfFG67rduDTCPqF_jDyLLfVaq5bu1z9zhiTtBxUUlRMiRqR4h0VIoLqmGNlr5Tirax11ZwJfgRE7sC9FLbLtfwW5pZKW1DQKF0iG8k9y-r6EIBfzTUfIwHPQylDdxOxjrSTD1-ncfAFcqqciBWphBhnPN8oCJ9UpDzOubEOZerWb6YjJu87JXl3Yb5xiftRXuAC6uWqXHAix3Td_lqnEyvbOAgaDJk9veMOxOSWw8bfLyeGDBqmYrlrU4w2_huuMUtrkRvJhsq-BspC2tUDhAdKdTBnjS2hlaOK113RtTDGoER7XEAJ2w1CchxFmROm1D8Cg0Q7KDrJtmRbWLiNsT-b2R3gZVT5MuPiiqZVD5KTUxKScMWbbpBDrlmiaqgW7E-rMbQytQCmrgUiqJd3UiGn-wyEq1_76k4_OtMmX-fbDd2Ge3lxi8lx9KbiJttz1jc5AxyOvmsjg9wQHPFhngm3rd4DbX0UATSy5q3qHDXpNfZ9YT_mlhTXOlmz1m6CmGw=w640-h480-s-no?authuser=0',
    title: '2018',
    width: '24%',
  },
  
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc99wZTMjStAxI5jw4YfqjGYB-4wNtKrHw7rAzNDgZvsEcMu6Twz5D0zwHjRHv7O2INn6SjQo6hh_d-0mMx5H4W9hK518m-UckU1cbdWjU5fcgn95k5OyDWXiWPPH1O_bseruvdbznzkDLyuLTiCddA2=w640-h480-s-no?authuser=0',
    title: '2017',
    width: '30%',
  },
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc_Zy7MU0Wt3j9MKWuCtS8lrQSYlEtGc1XJ6JbgEDXk-F3GlbBQpwtnbyog6tAlN5VnBHL1Dsgk5BnmRpM6ltDXR2wdKDbWFlrUvZ9tIe35ilmTVvfv0msjuM4FUGQsj8jHayGJDy0cujcHAnJppjWVz=w722-h963-s-no?authuser=0',
    title: '2016',
    width: '40%',
  },
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc_QVfzhyR7UowNzjn3DC_wvqJ6y4jBXQ6hjQmm1NjR1RUSJfmqCKdp9wwytJGBhhdUu5mSoF5D1SKeBl86sRQ0m-1F3eJVlJ4xVF26rSl3az6FBSn8_Sf56rraT_bQrUgvEYWnqy3JhA6x26ZX2zLDO=w548-h438-s-no?authuser=0',
    title: '2015',
    width: '30%',
  },
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc-EocOu0OlAgRXRyALtl6PtAsKIJv6_ijj5eS0pY29oFdZxgbwV883uhjMDwKwCw_MUInkRdgxkTFrJvIX9tiFGXfPuZdPIWRR28jJz_vOaVcYOpcVr7UDs9ZQHJNxmAf7-yNSJKxttI1S1mrpf5J2I=w1284-h963-s-no?authuser=0',
    title: '2014',
    width: '33%',
  },
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc8NM96x4Xj-WhoFvKtxzV5epEEQutespDC1tpNylxut502ShbeahzFPASTJ6KTuhw7l5WNY7_50IFdVRZRenYuLTRl0aB_0-pORXVCB-G6gdwP33-DbQXwiELkaVeg_eH2THmv5Xyg5x-k3-4s-5BxL=w704-h960-s-no?authuser=0',
    title: '2013',
    width: '33%',
  },
  
  {
    url: 'https://lh3.googleusercontent.com/pw/AIL4fc8UtArouGMyREqrNiwEz-bY88Hw0VcUW7OJgWjD-p_1rGJgs8NHC9ahhXQroyapmXEPhMhyS5lbYzrhgwL1bIZw9fYMcY-EZIHX3vu7R4F3Vyu7UVjQNMAjUomeJw9QY62iXjyl8qblmKmdkzboMtN6=w1338-h827-s-no?authuser=0',
    title: '2012',
    width: '33%',
  },
  
];

const images2 = [
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc8UtArouGMyREqrNiwEz-bY88Hw0VcUW7OJgWjD-p_1rGJgs8NHC9ahhXQroyapmXEPhMhyS5lbYzrhgwL1bIZw9fYMcY-EZIHX3vu7R4F3Vyu7UVjQNMAjUomeJw9QY62iXjyl8qblmKmdkzboMtN6=w1338-h827-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc8UtArouGMyREqrNiwEz-bY88Hw0VcUW7OJgWjD-p_1rGJgs8NHC9ahhXQroyapmXEPhMhyS5lbYzrhgwL1bIZw9fYMcY-EZIHX3vu7R4F3Vyu7UVjQNMAjUomeJw9QY62iXjyl8qblmKmdkzboMtN6=w1338-h827-s-no?authuser=0',
    originalHeight:'300px',
    originalTitle : '2012',
    description  :"2012"
    
  },
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc8NM96x4Xj-WhoFvKtxzV5epEEQutespDC1tpNylxut502ShbeahzFPASTJ6KTuhw7l5WNY7_50IFdVRZRenYuLTRl0aB_0-pORXVCB-G6gdwP33-DbQXwiELkaVeg_eH2THmv5Xyg5x-k3-4s-5BxL=w704-h960-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc8NM96x4Xj-WhoFvKtxzV5epEEQutespDC1tpNylxut502ShbeahzFPASTJ6KTuhw7l5WNY7_50IFdVRZRenYuLTRl0aB_0-pORXVCB-G6gdwP33-DbQXwiELkaVeg_eH2THmv5Xyg5x-k3-4s-5BxL=w704-h960-s-no?authuser=0',
    originalHeight:'300px',
    description: '2013',
    
  },
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc-EocOu0OlAgRXRyALtl6PtAsKIJv6_ijj5eS0pY29oFdZxgbwV883uhjMDwKwCw_MUInkRdgxkTFrJvIX9tiFGXfPuZdPIWRR28jJz_vOaVcYOpcVr7UDs9ZQHJNxmAf7-yNSJKxttI1S1mrpf5J2I=w1284-h963-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc-EocOu0OlAgRXRyALtl6PtAsKIJv6_ijj5eS0pY29oFdZxgbwV883uhjMDwKwCw_MUInkRdgxkTFrJvIX9tiFGXfPuZdPIWRR28jJz_vOaVcYOpcVr7UDs9ZQHJNxmAf7-yNSJKxttI1S1mrpf5J2I=w1284-h963-s-no?authuser=0',
    originalHeight:'300px',
    description: '2014',
    
  },
    {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc_QVfzhyR7UowNzjn3DC_wvqJ6y4jBXQ6hjQmm1NjR1RUSJfmqCKdp9wwytJGBhhdUu5mSoF5D1SKeBl86sRQ0m-1F3eJVlJ4xVF26rSl3az6FBSn8_Sf56rraT_bQrUgvEYWnqy3JhA6x26ZX2zLDO=w548-h438-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc_QVfzhyR7UowNzjn3DC_wvqJ6y4jBXQ6hjQmm1NjR1RUSJfmqCKdp9wwytJGBhhdUu5mSoF5D1SKeBl86sRQ0m-1F3eJVlJ4xVF26rSl3az6FBSn8_Sf56rraT_bQrUgvEYWnqy3JhA6x26ZX2zLDO=w548-h438-s-no?authuser=0',
    originalHeight:'300px',
    description: '2015',
    
  },
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc_Zy7MU0Wt3j9MKWuCtS8lrQSYlEtGc1XJ6JbgEDXk-F3GlbBQpwtnbyog6tAlN5VnBHL1Dsgk5BnmRpM6ltDXR2wdKDbWFlrUvZ9tIe35ilmTVvfv0msjuM4FUGQsj8jHayGJDy0cujcHAnJppjWVz=w722-h963-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc_Zy7MU0Wt3j9MKWuCtS8lrQSYlEtGc1XJ6JbgEDXk-F3GlbBQpwtnbyog6tAlN5VnBHL1Dsgk5BnmRpM6ltDXR2wdKDbWFlrUvZ9tIe35ilmTVvfv0msjuM4FUGQsj8jHayGJDy0cujcHAnJppjWVz=w722-h963-s-no?authuser=0',
    originalHeight:'300px',
    description: '2016',
    
  },
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc99wZTMjStAxI5jw4YfqjGYB-4wNtKrHw7rAzNDgZvsEcMu6Twz5D0zwHjRHv7O2INn6SjQo6hh_d-0mMx5H4W9hK518m-UckU1cbdWjU5fcgn95k5OyDWXiWPPH1O_bseruvdbznzkDLyuLTiCddA2=w640-h480-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc99wZTMjStAxI5jw4YfqjGYB-4wNtKrHw7rAzNDgZvsEcMu6Twz5D0zwHjRHv7O2INn6SjQo6hh_d-0mMx5H4W9hK518m-UckU1cbdWjU5fcgn95k5OyDWXiWPPH1O_bseruvdbznzkDLyuLTiCddA2=w640-h480-s-no?authuser=0',
    originalHeight:'300px',
    description: '2017',
    
  },
  {
    original:  'https://lh3.googleusercontent.com/pw/AIL4fc9LdVp-KZnYZftEPYh3q12LZjsbvWYWDBIvAB8Ne1SuD86T9m98WgxHPB-UHV1zaR4eZrdxupHfLjnpovC664m7IR9_M6Ft0R0TSllcFxO10QEar7rHlE9YyEB-n2LyYoZR2sHYtKMrN5hViRfZt5lH=w480-h345-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc9LdVp-KZnYZftEPYh3q12LZjsbvWYWDBIvAB8Ne1SuD86T9m98WgxHPB-UHV1zaR4eZrdxupHfLjnpovC664m7IR9_M6Ft0R0TSllcFxO10QEar7rHlE9YyEB-n2LyYoZR2sHYtKMrN5hViRfZt5lH=w480-h345-s-no?authuser=0',
    originalHeight:'300px',
    description: '2018',
    
  },
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc-1hNzqlaGMODnOo2_UviEsgltvYZYXVo5clzlT9izMl1GXjCZYuzr7hPHrWetnMGdKQacXY598pRzjtfKspX-D4FaxQtfOpfncYNMbciYInK4Bh-HLdwr9Dt7P6eaZ5a9S6JMWC9Ky36CBzvChy2bg=w722-h963-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc-1hNzqlaGMODnOo2_UviEsgltvYZYXVo5clzlT9izMl1GXjCZYuzr7hPHrWetnMGdKQacXY598pRzjtfKspX-D4FaxQtfOpfncYNMbciYInK4Bh-HLdwr9Dt7P6eaZ5a9S6JMWC9Ky36CBzvChy2bg=w722-h963-s-no?authuser=0',
    originalHeight:'300px',
    description: '2019',
    
  },
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc9SPy3EkrQ47zenvjA13t8s9gwqA9iVvxxDj17imPemZQV9vOnHmJ4uTmvTxqo2rDHQaruESjSFRwM8Xfq38N6bobzXuJp1i1MjpGS_hgcUzivwy8e9YPHhVOa_1NEMzO65E0zZxMwfWi9ASFnglo58=w1310-h900-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc9SPy3EkrQ47zenvjA13t8s9gwqA9iVvxxDj17imPemZQV9vOnHmJ4uTmvTxqo2rDHQaruESjSFRwM8Xfq38N6bobzXuJp1i1MjpGS_hgcUzivwy8e9YPHhVOa_1NEMzO65E0zZxMwfWi9ASFnglo58=w1310-h900-s-no?authuser=0',
    originalHeight:'300px',
    description: '2020',
    
  },
  
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc9HDauAakcfpjSkkJBOO6vhkyYf_ptZ828RdDUmFp__fYJeQpRN91Zgm67I-uib-Pm_NSXs_SxvepMocTtiSsIEZu8pHJYyVnP-qYgN9cjoqOCGvdDItiHT20mTdEX1Jvt4A8yDqFMR62t8yOWAdsib=w1055-h963-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc9HDauAakcfpjSkkJBOO6vhkyYf_ptZ828RdDUmFp__fYJeQpRN91Zgm67I-uib-Pm_NSXs_SxvepMocTtiSsIEZu8pHJYyVnP-qYgN9cjoqOCGvdDItiHT20mTdEX1Jvt4A8yDqFMR62t8yOWAdsib=w1055-h963-s-no?authuser=0',
    originalHeight:'300px',
    description: '2021',
    
  },
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc9irUS-gUPiZ2_juNJAaog6eYs_04JfaRgOTwX8soi3sSHVYS_RaIsVmIDRsp6pmegpfQoKjEmRNZqWt1W9eFrVZyA0bodawm3SDezlAp8DkiJmiQMItdWiIB9f4tkLOaq3Qpy8M10wduqfAm16CIAV=w1526-h963-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc9irUS-gUPiZ2_juNJAaog6eYs_04JfaRgOTwX8soi3sSHVYS_RaIsVmIDRsp6pmegpfQoKjEmRNZqWt1W9eFrVZyA0bodawm3SDezlAp8DkiJmiQMItdWiIB9f4tkLOaq3Qpy8M10wduqfAm16CIAV=w1526-h963-s-no?authuser=0',
    originalHeight:'300px',
    description: '2021',
    
  },
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc96EuJoWsIWisVN80c_eOheFmaPUTXBFggfrMJHIw-KAFV0l2xLC5R2GT1iRoywhDV8DRLrsnA2OGdnWo-Pxkso_M7ARSNKFmF9vqQJ2c2T7JP_B8ijksE2VWbw0w60npBzs_vnAMKXKlfrGODHDm_u=w855-h963-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc96EuJoWsIWisVN80c_eOheFmaPUTXBFggfrMJHIw-KAFV0l2xLC5R2GT1iRoywhDV8DRLrsnA2OGdnWo-Pxkso_M7ARSNKFmF9vqQJ2c2T7JP_B8ijksE2VWbw0w60npBzs_vnAMKXKlfrGODHDm_u=w855-h963-s-no?authuser=0',
    originalHeight:'300px',
    description: '2022',
    
  },
  {
    original: 'https://lh3.googleusercontent.com/pw/AIL4fc9giHv3UtIXvgcgRXEhSzUTTXAiypmY24bNZlhmlHL_S1m0k7UDbSMi0SK2EolsAhyinpHJD6lcpzYe56T3GzooKYNLAPvK5ZUPoCTEZVj8oOPASPS4ZmaAI0DG0ZVlHEQJ8g38j2eezS3-ZCZdnu2j=w894-h963-s-no?authuser=0',
    thumbnail: 'https://lh3.googleusercontent.com/pw/AIL4fc9giHv3UtIXvgcgRXEhSzUTTXAiypmY24bNZlhmlHL_S1m0k7UDbSMi0SK2EolsAhyinpHJD6lcpzYe56T3GzooKYNLAPvK5ZUPoCTEZVj8oOPASPS4ZmaAI0DG0ZVlHEQJ8g38j2eezS3-ZCZdnu2j=w894-h963-s-no?authuser=0',
    originalHeight:'300px',
    description: '2023',
    
  }
  
];

export default function ProductCategories() {
  return (
    <Container component="section" sx={{ mt: 8, mb: 4 }}>
      <p style={{fontFamily: 'MiFuentePersonalizada', fontSize:'50px',border:'0px',padding:'0px',margin:'0px',textAlign:'center'}}>
      Un poco de nuestra historia . . .
      </p>
      <Typography variant="h4" marked="center" align="center" component="h2">
        
      </Typography>
      <br/>

      <ImageGallery items={images2} 
        useTranslate3D
        originalHeight = "300px !important"
        onErrorImageURL = "Sorry no image"
      />

{/*
      <Box sx={{ mt: 8, display: 'flex', flexWrap: 'wrap' }}>
        {images.map((image) => (
          <ImageIconButton
            key={image.title}
            style={{
              width: image.width,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundSize: 'cover',
                backgroundPosition: 'center 40%',
                backgroundImage: `url(${image.url})`,
              }}
            />
            <ImageBackdrop className="imageBackdrop" />
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'common.white',
              }}
            >
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className="imageTitle"
              >
                {image.title}
                <div className="imageMarked" />
              </Typography>
            </Box>
          </ImageIconButton>
        ))}
      </Box>
            */}
    </Container>
  );
}
