import * as React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import TextField from '../components/TextField';
import Snackbar from '../components/Snackbar';
import Button from '../components/Button';

function ProductCTA() {
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="section" sx={{ mt: 10, display: 'flex' }}>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ zIndex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              bgcolor: '#AE0A58',
              py: 3,
              px: 3,
            }}
          >
            <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600 }}>
            
            <p style={{fontFamily: 'MiFuentePersonalizada', fontSize:'70px',border:'0px',padding:'0px',margin:'0px',textAlign:'center', color:'white'}}>
            Mesa de regalos
      </p>

              <Typography variant="h2" component="h2" gutterBottom style={{ color: 'white' }} >
                
              </Typography>
              <center>
              <Box
                  component="img"
                  src="/static/bodaimage/liverpool.png"
                  alt="suitcase"
                  sx={{
                    height: 90,
                    my: 0,
                  }}
                />
                </center>

                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open("https://mesaderegalos.liverpool.com.mx/milistaderegalos/51147891", '_blank');
                    }}
                  >
                    <br></br>
                   <p style={{ color: 'white', fontSize: 30 }}>  &#127873;  Mesa de regalos 51147891  &#127873; </p>
                  </Link>



                  <center>
              <Box
                  component="img"
                  src="/static/bodaimage/amazon_icon.png"
                  alt="suitcase"
                  sx={{
                    height: 90,
                    my: 0,
                  }}
                />
                </center>
                  <center>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open("https://www.amazon.com.mx/wedding/share/MesaRegalosLuisayDiego", '_blank');
                    }}
                  >
                    <br></br>
                   <p style={{ color: 'white', fontSize: 30 }}>  &#127873;  Mesa de regalos en amazon &#127873; </p>
                  </Link>
                  </center>
            </Box>




            
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { md: 'block', xs: 'none' }, position: 'relative' }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -67,
              left: -67,
              right: 0,
              bottom: 0,
              width: '100%',
              background: 'url(/static/themes/onepirate/productCTAImageDots.png)',
            }}
          />
          

          
        </Grid>
      </Grid>
     
    </Container>
  );
}

export default ProductCTA;
