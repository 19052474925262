import * as React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';


const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      
        
      <Container sx={{ mt: 10, mb: 10, display: 'flex', position: 'relative' }}>
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: 'none', position: 'absolute', top: -150 }}
        />

 




        
        <Grid container spacing={2}>
        
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
          <p style={{fontFamily: 'MiFuentePersonalizada', fontSize:'40px',border:'0px',padding:'0px',margin:'0px',textAlign:'center'}}  >
       En este dia tan especial, nos gustaría tu compañia en . . .<br></br> 
            </p>

            
          </Grid>
        </Grid>
      
          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/bodaimage/church.svg"
                alt="suitcase"
                sx={{ height: 85 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
              <center>Ceremonia Religiosa</center>
              </Typography>
              <Typography variant="h5">
                <center>
                {
                  'Parroquia San Felipe De Jesús'
                }</center>
                <br></br>
                <center>
                {
                  '13:30 hrs '
                }
                </center>
                <br></br><center>
                {
                  'Plaza de La parroquia # 460 Lomas de Cuernavaca, Temixco Mor. C.P. 62584. '
                }
                </center>
              </Typography>
              <span>
                <br></br>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open("https://goo.gl/maps/zJko2wQoAstgCMH78", '_blank');
                    }}
                  >
                    <Typography variant="h5">
                     
                     <Box
                        component="img"
                        src="/static/bodaimage/pin.png"
                        alt="suitcase"
                        sx={{ height: 55 }}
                      />
                      <br></br>
                      <span> Abrir mapa </span>
                    </Typography>
                  </Link>
                </span>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={item}>
              <Box
                component="img"
                src="/static/bodaimage/cheers.svg"
                alt="graph"
                sx={{ height: 85 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Recepción
              </Typography>
              <Typography variant="h5">
                <center>
                {
                  'Jardin Yalorde'
                }</center>
                <br></br>
                <center>
                {
                  '16:00 hrs '
                }
                </center>
                <br></br><center>
                {
                  'Paseo de Las Fuentes, 62554 Jiutepec, Morelos.'
                }
                </center>


              </Typography>
              <span>
                <br></br>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open("https://goo.gl/maps/c2jimvRS5rqeCwdc7", '_blank');
                    }}
                  >
                    <Typography variant="h5">
                     
                     <Box
                        component="img"
                        src="/static/bodaimage/pin.png"
                        alt="suitcase"
                        sx={{ height: 55 }}
                      />
                      <br></br>
                      <span> Abrir mapa </span>
                    </Typography>
                  </Link>
                </span>
            </Box>
          </Grid>
          
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
